import React, { useState, useEffect } from "react";
import feedback from '../assets/images/feedback.png'
import narrator from '../assets/images/narrator.png'
import aboutUs from '../assets/images/aboutUs.png'
import signout from '../assets/images/signout.png'
import '../styles/DropDown.css';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ellipsisVertical from '../assets/icons/ellipsis-vertical.svg'
import { sendFeedback } from "../utils/Api";
import { toast } from 'react-toastify';
import { handleLoaderEvent } from '../utils/LoaderEmitter';

function DropDown({ mSALObj }) {

    const [feedbackModalShow, setFeedbackModalShow] = useState(false);
    const [email, setEmail] = useState(mSALObj.getAccount()?.idToken?.emails[0]);
    const [rating, setRating] = useState(0)
    const [formSubmitStatus,setFormSubmitStatus]=useState(false)


    const feedbackModalHandleClose = () => {
        setFeedbackModalShow(false);
        setRating(0)
        setFormSubmitStatus(false)
    }
    const feedbackModalHandleOpen = () => {
        setFeedbackModalShow(true);
    }
    const logoutProcess = () => {
        localStorage.clear();
        sessionStorage.clear();
        mSALObj.logout();
    }
    const feebackSchema = Yup.object().shape({
        comments: Yup.string().required('Comments is required'),
        email: Yup.string().email('Enter valid email id').required('Email is required')
    });

    const handleSubmit = async (values) => {
        setFormSubmitStatus(true)
        if(rating!=0)
        {
            handleLoaderEvent.emit('loader', true);

        let params = {
                "title":"feedback",
                "data":
                {
                    "rating":rating,
                    "comments":values.comments,
                    "emailaddress":values.email
                }

        };

        const response=await sendFeedback(params)
        feedbackModalHandleClose()

        if(response)
        {
            toast.info("Feedback successfully submitted")
        }
        else{
            toast.error("Something went wrong")
        }

        handleLoaderEvent.emit('loader', false);
        }

    };

    return (
        <>
            <div className="btn-group">
                {/* <button type="button" className="btn dropdown-toggle no-arrow" data-bs-toggle="dropdown" aria-expanded="false"> */}
                <img src={ellipsisVertical} className="menu-icon btn dropdown-toggle no-arrow" data-bs-toggle="dropdown" aria-expanded="false"></img>
                {/* </button> */}
                <ul className="dropdown-menu dropdown-menu-end">
                    {/* <li><a className="dropdown-item"><img className="navigation-icons" src={narrator} />Narrator</a></li> */}
                    <li><a className="dropdown-item" onClick={feedbackModalHandleOpen}><img className="navigation-icons" src={feedback}>
                    </img>Share Feedback</a></li>
                    <li><a className="dropdown-item" href="https://ariya.ai" target="_blank"> <img className="navigation-icons" src={aboutUs} />About Ariya</a></li>
                    <li><a className="dropdown-item" onClick={logoutProcess}> <img className="navigation-icons" src={signout} />Sign Out</a></li>
                </ul>
            </div>

            <Modal className='feedback-modal' show={feedbackModalShow} onHide={feedbackModalHandleClose} style={{"backgroundColor": 'rgba(0, 0, 0, 0.7)'}}>
                <Modal.Body>
                    <div className="container modal-body">
                        <div className="row">
                            <div className="offset-1 col-10 form-label">
                                How would you rate your experience ?
                            </div>
                        </div>
                        <div className="row mt-2 emoji-row">
                            <div className={rating === 1 ? "offset-1 col-2 emoji-selected-1" : "offset-1 col-2 emoji"} onClick={() => setRating(1)}>&#x1F61E;</div>
                            <div className={rating === 2 ? " col-2 emoji-selected-2" : "col-2 emoji"} onClick={() => setRating(2)}>&#x1F641;</div>
                            <div className={rating === 3 ? "col-2 emoji-selected-3" : "col-2 emoji"} onClick={() => setRating(3)}>&#x1F610;</div>
                            <div className={rating === 4 ? "col-2 emoji-selected-4" : "col-2 emoji"} onClick={() => setRating(4)}>&#x1F642;</div>
                            <div className={rating === 5 ? " col-2 emoji-selected-5" : "col-2 emoji"} onClick={() => setRating(5)}>&#x1F601;</div>
                        </div>
                        {
                            rating === 0  && formSubmitStatus===true ? <div className="row">
                                <div className="offset-1 mt-2 invalid-text">
                                    Please select rating
                                </div>
                            </div> : <></>
                        }

                        <div className="row">
                            <div className="offset-1 col-10 mt-2">

                                <Formik
                                    initialValues={{
                                        comments:"",
                                        email: email,
                                    }}
                                    validationSchema={feebackSchema}
                                    onSubmit={values => {
                                        handleSubmit(values);
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='row mt-2 feedback-input'>
                                                <label className="form-label">
                                                    Write your comments or suggestions
                                                </label>
                                                <Field name="comments" type="text" className="" />
                                                {errors.comments && touched.comments ? (
                                                    <div className="invalid-text mt-1">{errors.comments}</div>
                                                ) : null}

                                            </div>
                                            <div className='row mt-2 mb-2 feedback-input'>
                                                <label className="form-label">
                                                    Email Address
                                                </label>
                                                <Field name="email" type="email"  className="feedback-input" disabled={true} />
                                                {errors.email && touched.email ? <div className="invalid-text mt-1">{errors.email}</div> : null}

                                            </div>
                                            <div className="row disclaimer">
                                                Disclaimer: By submitting, you allow us to contact you back for interaction
                                            </div>
                                            <div className='d-flex justify-content-end mt-5'>
                                                <button type="button" className='cancel-button p-2 px-4 me-3' onClick={feedbackModalHandleClose}>Cancel</button>
                                                <button type="submit" className='save-button p-2 px-4'>Save</button>
                                            </div>

                                        </Form>)}
                                </Formik>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default DropDown
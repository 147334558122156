import axios from 'axios';
import { toast } from 'react-toastify';

export const axiosRequest = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000/",
});

const getHeader = () => {
    return { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') };
}

export const getLoggedInUserDetails = async (cognitoId) => {
    try {
        let headers = getHeader();
        if (!headers) {
            return;
        }
        let res = await axiosRequest.get(`users?cognitoId=${cognitoId}`, { headers });
        return res.data;
    } catch (exception) {
        if (exception == 'Login' || exception.response.status == 401) {
            toast.error("Unauthorized Access");
            // handleAuthEvent.emit('login', true);
            return false;
        }
        toast.error('Something went wrong');
        return false;
    }
}

export const fetchUserImage = async () => {
    try {
        let headers = getHeader();
        if (!headers) {
            return;
        }

        let res = await axiosRequest.get("https://graph.microsoft.com/v1.0/me/photo/$value", { headers });
        return res.blob();
    } catch (exception) {
        console.log('exception', exception)
        if (exception == 'Login' || exception.response.status == 401) {
            toast.error("Unauthorized Access");
            // handleAuthEvent.emit('login', true);
            return false;
        }
        toast.error('Something went wrong');
        return false;
    }
}
export const sendFeedback = async (params) => {
    try {
        let res = await axios.post(process.env.REACT_APP_EMAIL_URL,params)

        return true
    }
    catch (exception) {

        return false
    }

}
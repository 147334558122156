export default function getConfig() {
    return {
        "msalConfig": {
            "auth": {
                "clientId": "158a4ce0-3049-49cc-8d66-6301bed47a40",
                "authority": "https://ariyaai.b2clogin.com/ariyaai.onmicrosoft.com/B2C_1_login",
                "validateAuthority": false,
                "postLogoutRedirectUri": process.env.REACT_APP_REDIRECT_URL || "http://localhost:3000/",
            },
            "cache": {
                "storeAuthStateInCookie": false
            }
        },
        "b2cPolicies": {
            "names": {
                "signUpSignIn": "B2C_1_login",
                "forgotPassword": "B2C_1_forgotPassword"
            },
            "authorities": {
                "signUpSignIn": {
                    "authority": "https://ariyaai.b2clogin.com/ariyaai.onmicrosoft.com/B2C_1_login"
                },
                "forgotPassword": {
                    "authority": "https://ariyaai.b2clogin.com/ariyaai.onmicrosoft.com/B2C_1_forgotPassword"
                }
            }
        },
        "loginRequest": {
            "scopes": [
                "openid",
                "profile"
            ]
        },
        "apiConfig": {
            "b2cScopes": [
                "https://ariyaai.onmicrosoft.com/158a4ce0-3049-49cc-8d66-6301bed47a40/demo.read"
            ],
            "webApi": "http://localhost:6420"
        },
        "tokenRequest": {
            "scopes": [
                "https://ariyaai.onmicrosoft.com/158a4ce0-3049-49cc-8d66-6301bed47a40/demo.write"
            ]
        },
        "apiSecurityAuthentication": {
        }
    };
}
import './styles/App.css';
import Webchat from './components/Webchat';
import * as Msal from "msal";
import getConfig from './configs/config';
import React, { useState, useEffect } from "react"
import waves from './assets/images/waves.svg'
import poweredByAriya from './assets/images/poweredByAriya.svg'
import headerImage from './styles/header-image.png';
import DropDown from './components/DropDown';
import Avatar from 'react-avatar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Loader from "./components/Loader";
import Speech from './components/Speech';
import {createStore } from 'botframework-webchat';


const loginConfig = getConfig()
function App() {

  const store = createStore();

  const mSALObj = new Msal.UserAgentApplication(loginConfig.msalConfig);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleRedirectCallback = async () => {
    return await mSALObj.handleRedirectCallback(authRedirectCallBack);
  }

  const authRedirectCallBack = async (error, response) => {
    if (error) {
      if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        try {
          await mSALObj.loginRedirect(loginConfig.b2cPolicies.authorities.forgotPassword);
        } catch (err) {
        }
      }
    } else {
      console.log('no error');
    }
  }


  useEffect(() => {
    handleRedirectCallback();

    (async () => {
      const accountDetails = mSALObj.getAccount();
      setIsLoggedIn(accountDetails ? true : false);
      const checkLoggedIn = accountDetails ? true : false;
      if (checkLoggedIn) {
        let emailId = accountDetails.idToken.emails[0];
        let name = accountDetails['name'];
        localStorage.setItem('name', name);
        localStorage.setItem('email', emailId);
        const ssoRequest = { loginHint: emailId };
        const response = await mSALObj.ssoSilent(ssoRequest);
        sessionStorage.setItem('token', response['idToken']['rawIdToken']);
      } else {
        mSALObj.loginRedirect(loginConfig.loginRequest);
      }
    })();
  }, []);


  return (
    isLoggedIn ? (
      <div className='container-fluid p-0 bg-container d-flex justify-content-center align-items-center'>
        <ToastContainer />
        <Loader />
        <div className="webchat-container position-relative">
          <div>
            <img className="webchat-header" src={headerImage} />
          </div>
          <div className="user-detail-header row p-0 m-0 w-100 p-1 px-2">
            <div className="col p-0 d-flex align-items-center">
              <img className="company-logo" src="/companyLogo.svg" />
            </div>
            <div className='col'>

            </div>
            <div className='col-auto px-1 pe-2 d-flex align-items-center'>
              <div className='text-end'>
                <div className='user-name'>Hi, {localStorage.getItem('name')}</div>
                <div className='login-time'>{new Date().toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' })}</div>
              </div>
            </div>
            <div className='col-auto px-1'>
              <Avatar name={localStorage.getItem('name')} size="40" round={true} />
            </div>
            <div className='col-auto p-0'>
              <DropDown mSALObj={mSALObj} />
            </div>
          </div>
          <div className='chat-box'>
            <Webchat store={store}/>
            {window.location.hostname !== "schwabe.ariya.ai"  && <Speech store={store}/>}
          </div>

          <img className="waves-img" src={waves} />
          <img className="powered-by-ariya-img" src={poweredByAriya} />
          <div className='copyright'>
            © phamax AG, {new Date().getFullYear()} - All Rights Reserved
          </div>
        </div>
        <div className='bottom-border'></div>
      </div>
    ) : (
      <div></div>
    )
  );
}

export default App;

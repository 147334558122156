import React, { useState, useEffect } from 'react';
import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat';
import Avatar from 'react-avatar';
import UrlModal from './UrlModal';

export default ({ store }) => {
	const [token, setToken] = useState(null);

	const styleOptions = {
		botAvatarInitials: 'Bot',
		userAvatarInitials: <Avatar name={localStorage.getItem('name')} size="40" round={true} />,
		bubbleBackground: '#FFFFFF',
		bubbleBorderColor: '#FFFFFF',
		bubbleBorderRadius: 4,
		bubbleBorderWidth: 2,
		bubbleNubOffset: 0,
		bubbleNubSize: 10,

		bubbleFromUserBackground: '#0070c0',
		bubbleFromUserBorderColor: '#0070c0',
		bubbleFromUserTextColor: '#FFF',
		bubbleFromUserBorderRadius: 4,
		bubbleFromUserBorderWidth: 2,
		bubbleFromUserNubOffset: 0,
		bubbleFromUserNubSize: 10,

		groupTimestamp: 3000,
		showAvatarInGroup: 'status',
		botAvatarImage: './logo.png'
	};


	const attachmentMiddleware = () => (next) => (card) => {
		switch (card.attachment.contentType) {
			case 'application/vnd.microsoft.botframework.modal':
				return <UrlModal url={card.attachment.content.url} title={card.attachment.content.title} />;

			default:
				return next(card);
		}
	};

	async function fetchToken() {
		try {
			const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
			const url = baseUrl ? `${baseUrl}/directline-token` : '/directline-token';
			const response = await fetch(url);
			const data = await response.json();
			const generatedToken = data.token;
			setToken(generatedToken);
		} catch (error) {
			console.log(' Unable to get direct line token error');
		}
	}

	useEffect(() => {
		fetchToken();
	}, []);

	const directLine = createDirectLine({
		token
	});

	return <ReactWebChat className="webchat__chat" 
	directLine={directLine} 
	styleOptions={styleOptions} 
	attachmentMiddleware={attachmentMiddleware} 
	store={store}
	username={localStorage.getItem('email')}
	/>;
};

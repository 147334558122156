import React, { useEffect, useState } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';


const Speech = ({ store }) => {

	let speechConfig = sdk.SpeechConfig.fromSubscription(process.env.REACT_APP_SPEECH_TO_TEXT_SUBSCRIPTION_KEY, process.env.REACT_APP_SPEECH_TO_TEXT_REGION);
	speechConfig.speechRecognitionLanguage = process.env.REACT_APP_SPEECH_RECOGNITION_LANGUAGE;

	const [showAnim, setShowAnim] = useState(false);

	const speechToText = () => {
		let speechRecognizer = new sdk.SpeechRecognizer(speechConfig);
		setShowAnim(true);

		speechRecognizer.recognizeOnceAsync((result) => {
			switch (result.reason) {
				case sdk.ResultReason.RecognizedSpeech:
					store.dispatch({
						type: 'WEB_CHAT/SEND_MESSAGE',
						meta: { method: 'speech' },
						payload: { text: result.text }
					});
					break;
				case sdk.ResultReason.Canceled:
					const cancellation = sdk.CancellationDetails.fromResult(result);
					break;
			}
			setShowAnim(false);
			speechRecognizer.close();
		});
	};

	return (
		showAnim ?
		<div className="dot-box">
			<div className="dot-elastic" id="animatedButton"></div>
		</div>
		:
		<div className="microphone-box" onClick={speechToText}>
			<img src="https://ariyav1-cdn.azureedge.net/images/microphone.png" className="microphone-icon" />
		</div>
	);
};

export default Speech;

import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import '../styles/Loader.css'
import { handleLoaderEvent } from '../utils/LoaderEmitter';

function Loader() {

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const loaderHandler = async (detail) => {
            setShowLoader(detail);
        }

        handleLoaderEvent.on('loader', loaderHandler);
    }, []);

    return (
        showLoader &&
        <div className='custom-loader d-flex justify-content-center align-items-center'>
            <TailSpin
                height="80"
                width="80"
                color="#0C006B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

export default Loader